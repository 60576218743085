<template>
  <div class="chats">
    <div v-if="loadingMore" class="text-center">
      <b-spinner small />
    </div>
    <div v-else-if="loadingError" class="text-center text-danger">
      {{ $t('chat.error-messages.load-of-more-messages-failed') }}
    </div>
    <div v-for="(msgDayGrp, dayIndex) in formattedChatData" :key="`${msgDayGrp.day}-${dayIndex}`">
      <div class="text-center">
        <b-badge pill variant="primary" class="mx-auto mt-2 mb-1">
          {{ $d(new Date(msgDayGrp.day), 'long') }}
        </b-badge>
      </div>
      <div v-for="(msgGrp, senderIndex) in msgDayGrp.messages" :key="`${msgGrp.senderId}-${senderIndex}`">
        <div class="chat" :class="{ 'chat-left': !isLoggedUser(msgGrp.senderId) }">
          <div class="chat-avatar">
            <user-avatar
              :user="getUser(msgGrp.senderId)"
              size="32"
              variant="transparent"
              class="avatar-border-2 box-shadow-1"
            />
          </div>
          <div class="chat-body">
            <div v-for="(msgData, msgIndex) in msgGrp.messages" :key="msgData.id" class="chat-content">
              <small v-if="!isLoggedUser(msgGrp.senderId) && msgIndex === 0" class="text-primary">
                {{ getName(msgGrp.senderId) }}
              </small>
              <content-with-anchors :content="msgData.msg" />
              <div class="d-flex justify-content-end">
                <small :class="!isLoggedUser(msgGrp.senderId) ? 'text-muted' : 'text-white'" class="mt-1">
                  {{ msgData.time | moment('HH:mm') }}
                </small>
              </div>
              <feather-icon
                v-if="msgData.isFailure || msgData.isPending"
                v-b-tooltip.hover.bottom
                class="chat-status-icon"
                :class="{ 'text-danger': msgData.isFailure }"
                :icon="msgData.isFailure ? 'AlertCircleIcon' : msgData.isPending ? 'ClockIcon' : ''"
                :title="msgData.isFailure ? 'Error al enviar' : msgData.isPending ? 'Enviando...' : ''"
                size="8"
              />
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
// Modified from Vuexy
import moment from 'moment';
import { computed } from '@vue/composition-api';
import { VBTooltip, BSpinner, BBadge } from 'bootstrap-vue';
import FeatherIcon from '@/@core/components/feather-icon/FeatherIcon.vue';
import ContentWithAnchors from '@core/components/string-utils/ContentWithAnchors.vue';
import UserAvatar from '@core/components/user/UserAvatar.vue';

export default {
  components: {
    UserAvatar,
    BSpinner,
    FeatherIcon,
    BBadge,
    ContentWithAnchors,
  },
  directives: { 'b-tooltip': VBTooltip },
  props: {
    chatData: {
      type: Object,
      required: true,
    },
    loadingMore: Boolean,
    loadingError: Boolean,
  },
  setup(props) {
    const formattedChatData = computed(() => {
      const chatLog = props.chatData.chat || [];

      const formattedChatLog = [];
      let msgDayGroup = {
        day: chatLog[0]
          ? moment(chatLog[0].time)
            .startOf('day')
            .toISOString()
          : undefined,
        messages: [],
      };

      chatLog.forEach((msg, index) => {
        const msgDay = moment(msg.time)
          .startOf('day')
          .toISOString();
        if (msgDayGroup.day !== msgDay) {
          // The day has changed, push the current day and start a new one.
          formattedChatLog.push(msgDayGroup);
          msgDayGroup = {
            day: msgDay,
            messages: [],
          };
        }

        // If the messages are more than 5 minutes appart, break them
        let isAppart = false;
        const lastSenderMessageGroup = msgDayGroup.messages[msgDayGroup.messages.length - 1];
        if (lastSenderMessageGroup?.messages.length > 0) {
          const previousMessage = lastSenderMessageGroup.messages[lastSenderMessageGroup.messages.length - 1];
          isAppart = moment(msg.time).isAfter(moment(previousMessage.time).add(5, 'minutes'));
        }

        if (
          !msgDayGroup.messages.length
          || msgDayGroup.messages[msgDayGroup.messages.length - 1].senderId !== msg.senderId
          || isAppart
        ) {
          // a new sender has started chatting, change the senderId and add it's first message
          msgDayGroup.messages.push({
            senderId: msg.senderId,
            messages: [
              {
                id: msg.messageID,
                msg: msg.message,
                time: msg.time,
                isPending: msg.isPending,
                isFailure: msg.isFailure,
              },
            ],
          });
        } else {
          // The same sender is chatting
          msgDayGroup.messages[msgDayGroup.messages.length - 1].messages.push({
            id: msg.messageID,
            msg: msg.message,
            time: msg.time,
            isPending: msg.isPending,
            isFailure: msg.isFailure,
          });
        }

        if (index === chatLog.length - 1) formattedChatLog.push(msgDayGroup);
      });

      return formattedChatLog;
    });

    const getName = (userId) => {
      const member = props.chatData.contactsInformationMap[userId];
      return member ? `${member.name} ${member.surname}` : '';
    };

    const isLoggedUser = (userId) => userId === props.chatData.loggedUser.key;
    
    // Beware, it's actually the member
    const getUser = (userId) => {
      if (isLoggedUser(userId)) return props.chatData.loggedMember || props.chatData.loggedUser;

      return props.chatData.contactsInformationMap[userId];
    };

    return {
      formattedChatData,
      getUser,
      getName,
      isLoggedUser,
    };
  },
};
</script>

<style lang="scss" scoped>
.chat-content {
  position: relative;
  .chat-status-icon {
    position: absolute;
    bottom: 3px;
    right: 3px;
  }
}
.chat .b-avatar {
  color: inherit;
}
.loading-more {
  text-align: center;
}
.loading-error {
  text-align: center;
}
</style>
