import store from '@/store';

/**
 * Hook that registers a module with the given name.
 * It will unregister the module on unmount.
 *
 * @param {String} moduleName The name of the module to register
 * @param {Object} storeModule The module to be registred
 * @returns The store
 */
export default function useStoreModule(moduleName, storeModule) {
  // Register module
  if (!store.hasModule(moduleName)) store.registerModule(moduleName, storeModule);

  return store;
}
