<template>
  <list-container-widget
    id="chat"
    :title="title"
    :app-id="appId"
    :class="{ stretch }"
    :stretch="stretch"
    :individual-chat="individualChat"
    class="d-flex chat-widget mb-0"
  >
    <template
      #header="{
        computedTitle,
        isEditable,
        isEditingName,
        setIsEditingName,
      }"
    >
      <b-card-header>
        <div class="d-flex align-items-center">
          <slot name="room-avatar" :src="communityAvatar" />
          <h4 class="mb-0 pl-1">
            <div>
              {{ computedTitle }}
              <b-button
                v-if="isEditable"
                variant="link"
                class="p-0"
                :pressed="isEditingName"
                @update:pressed="setIsEditingName"
              >
                <feather-icon icon="Edit2Icon" size="16" />
              </b-button>
            </div>
          </h4>
        </div>
        <div>
          <slot name="header" />
        </div>
      </b-card-header>
    </template>

    <section class="chat-app-window">
      <!-- User Chat Area -->
      <app-scroll
        ref="refChatLogPS"
        :options="{ scrollPanel: { scrollingX: false } }"
        class="user-chats scroll-area"
        @ps-y-reach-start="fetchOldMessages"
      >
        <template v-if="!chatFailed">
          <div v-if="isLoading" class="centered-message w-100">
            <b-spinner />
          </div>
          <div v-else-if="isChatEmpty" class="centered-message">
            <b-img :src="placeholderImage" class="placeholder-img" center />
            <div v-if="emptyMessage" class="mt-1">
              <p class="text-primary">
                {{ emptyMessage }}
              </p>
            </div>
          </div>
          <chat-log
            v-else
            :chat-data="chatData"
            :loading-more="isLoadingOldMessages"
            :loading-error="loadOfOldMessagesFailed"
          />
        </template>
        <div v-else class="centered-message text-danger align-items-center">
          <b-img :src="placeholderFailImage" class="placeholder-img" center />
          <p style="color:#ea5455" class="mt-1 mx-1">
            {{ $t('chat.error-messages.chat-connection-failed') }}
          </p>
        </div>
      </app-scroll>
      <!-- Message Input -->
      <b-form class="chat-app-form" @submit.prevent="sendMessage">
        <b-input-group class="input-group-merge form-send-message mr-1" @keyup.enter="sendMessage">
          <plain-editor
            v-model="chatInputMessage"
            class="form-control text-editor"
            :placeholder="$t('message.content')"
            :disabled="isDisabled"
          />
        </b-input-group>
        <b-button variant="primary" type="submit" :disabled="isDisabled">
          {{ $t('send.button') }}
        </b-button>
      </b-form>
    </section>
  </list-container-widget>
</template>

<script>
import {
  BCardHeader, BForm, BInputGroup, BButton, BSpinner, BImg,
} from 'bootstrap-vue';
import AppScroll from '@core/components/app-scroll/AppScroll.vue';
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue';
import useStoreModule from '@/@core/comp-functions/store/module-registration';

import ListContainerWidget from '@core/widgets/ListContainerWidget.vue';
import PlainEditor from '@core/components/editor/PlainEditor.vue';
import ChatPlaceholder from '@/assets/images/placeholders/light/chat.svg';
import ChatFailPlaceholder from '@/assets/images/placeholders/light/fail-connection.svg';
import { getImageResource } from '@/@core/utils/image-utils';

import ChatError from '../constants/chat-errors';
import chatStoreModule from '../store/chatStoreModule';
import {
  CHAT_STORE_MODULE_NAME, CHAT_ACTIONS, CHAT_GETTERS, CHAT_MUTATIONS,
} from '../constants/chat-store-constants';
import ChatLog from '../components/ChatLog.vue';

export default {
  name: 'BasicChatWidget',
  components: {
    ListContainerWidget,
    PlainEditor,
    BCardHeader,
    BForm,
    BInputGroup,
    BButton,
    BImg,
    // SFC
    ChatLog,
    BSpinner,

    // 3rd party
    AppScroll,
  },
  props: {
    target: {
      type: Object,
      required: true,
    },
    appId: {
      type: Number,
      default: null,
    },
    title: {
      type: String,
      default: '',
    },
    autofocus: Boolean,
    // If true, then the widget will stretch to fill all the available vertical space
    stretch: Boolean,
    emptyMessage: {
      type: String,
      default: '',
    },
    individualChat: Boolean,
  },
  data() {
    return {
      chatInputMessage: '',
      // The name of the room with the roomType included
      targetName: '',
      chatError: '',
      isLoadingOldMessages: false,
      shouldUpdateScroll: false,
      hasNoMoreMessages: false,
      isMounted: false,

      isConnecting: true,

      unsubscribeAction: null,
      unsubscribe: null,
      isAppInfoModalVisible: false,
    };
  },
  computed: {
    isLoading() {
      return this.isConnecting;
    },
    loggedUser() {
      return this.$store.getters.loggedUser;
    },
    loggedMember() {
      return this.$store.getters.loggedMember;
    },
    chat() {
      return this.$store.getters[CHAT_GETTERS.chat](this.targetName) || [];
    },
    contacts() {
      return this.$store.getters[CHAT_GETTERS.contacts];
    },
    contactsInformationMap() {
      return this.$store.getters[CHAT_GETTERS.contactsInformationMap];
    },
    chatData() {
      return {
        chat: this.chat,
        loggedUser: this.loggedUser,
        loggedMember: this.loggedMember,
        contacts: this.contacts,
        contactsInformationMap: this.contactsInformationMap,
      };
    },
    isChatEmpty() {
      return this.chat.length === 0;
    },
    oldestMessageTime() {
      return this.chat.length !== 0 ? this.chat[0].time : new Date().toISOString();
    },
    chatFailed() {
      return [
        ChatError.ConnectionFailed,
        ChatError.JoinRoomFailed,
        ChatError.ListUsersFailed,
        ChatError.InitPersonalChatFailed,
      ].includes(this.chatError);
    },
    loadOfOldMessagesFailed() {
      return this.chatError === ChatError.MessagesHistoryFailed;
    },
    isDisabled() {
      return this.chatFailed || this.isConnecting;
    },
    communityAvatar() {
      return getImageResource(
        this.$store.getters.currentCollective.header?.logo_url || this.$store.getters.currentCollective.logoURL,
      );
    },
  },
  setup() {
    useStoreModule(CHAT_STORE_MODULE_NAME, chatStoreModule);

    return { placeholderImage: ChatPlaceholder, placeholderFailImage: ChatFailPlaceholder };
  },
  async mounted() {
    this.isMounted = false;
    this.scrollToBottom();

    this.isConnecting = true;
    await this.$store.dispatch(CHAT_ACTIONS.connect, { callback: this.handleConnectionReady });
  },
  beforeDestroy() {
    if (this.unsubscribeAction) this.unsubscribeAction();
    if (this.unsubscribe) this.unsubscribe();

    this.$store.dispatch(CHAT_ACTIONS.closeConnection, { callback: this.handleConnectionReady });
  },
  methods: {
    async handleConnectionReady(event) {
      if (event?.error) {
        this.chatError = event.error;
        return;
      }

      // Subscribe to new message event, to be called before and after the store's actions.
      const scrollTriggerActions = [CHAT_ACTIONS.handleMessageEvent, CHAT_ACTIONS.sendMessage];
      this.unsubscribeAction = this.$store.subscribeAction({
        before: ({ type, payload: message }) => {
          if (scrollTriggerActions.includes(type) && message.targetName === this.targetName) {
            this.beforeNewMessageCallback(message);
          }
        },
        after: ({ type, payload: message }) => {
          if (scrollTriggerActions.includes(type) && message.targetName === this.targetName) {
            this.afterNewMessageCallback(message);
          }
        },
      });

      // subscribe to session lost mutation. It tries to reconnect.
      this.unsubscribe = this.$store.subscribe((mutation) => {
        if (mutation.type === CHAT_MUTATIONS.sessionLost) {
          this.$store.commit(CHAT_MUTATIONS.addRecoverSessionHandler, {
            handler: async () => this.connectionRecovered(true),
          });
        }
      });

      if (this.target.room) {
        const alreadyConnected = await this.joinRoom();

        if (alreadyConnected === null) {
          // something failed.
          return;
        }

        if (!alreadyConnected) {
          try {
            await this.$store.dispatch(CHAT_ACTIONS.listRoomUsers, {
              room: this.target.room,
              roomType: this.target.roomType,
              fullRoomName: this.targetName,
            });
          } catch {
            this.isConnecting = false;
            this.chatError = ChatError.ListUsersFailed;

            return;
          }

          await this.fetchOldMessages();
        } else {
          // Messages have already been loaded to the store, just scroll to the bottom of them.
          await this.$nextTick();
          this.scrollToBottom();
        }
      } else {
        this.targetName = this.target.user.userKey;
        await this.initPersonalChat();
        await this.fetchOldMessages();
      }

      this.isConnecting = false;
      this.isMounted = true;
    },
    async joinRoom() {
      try {
        const response = await this.$store.dispatch(CHAT_ACTIONS.joinRoom, {
          room: this.target.room,
          roomType: this.target.roomType,
        });
        this.targetName = response.fullRoomName;
        return response.alreadyConnected;
      } catch {
        this.isConnecting = false;
        this.chatError = ChatError.JoinRoomFailed;

        return null;
      }
    },
    async initPersonalChat() {
      try {
        await this.$store.dispatch(CHAT_ACTIONS.initPersonalChat, { user: this.target.user });
      } catch {
        this.chatError = ChatError.InitPersonalChatFailed;
      }
    },
    async connectionRecovered(wasSessionLost) {
      if (!wasSessionLost) return;

      if (this.target.room) {
        await this.joinRoom();
      } else {
        await this.initPersonalChat();
      }
    },
    sendMessage() {
      if (!this.chatInputMessage.trim()) {
        return;
      }

      let chatInputMessageWithoutSpaces = this.chatInputMessage;
      const wordsToReplace = [' ', '<br>', '<p>', '</p>'];
      wordsToReplace.forEach((word) => {
        chatInputMessageWithoutSpaces = chatInputMessageWithoutSpaces.replaceAll(word, '');
      });

      if (!chatInputMessageWithoutSpaces.length > 0) {
        return;
      }

      this.$store.dispatch(CHAT_ACTIONS.sendMessage, {
        target: this.target.user ? this.target.user.userKey : this.target.room,
        targetType: this.target.user ? 'user' : this.target.roomType,
        targetName: this.targetName,
        message: this.chatInputMessage,
        timestamp: Date.now(),
        senderId: this.loggedUser.key,
      });
      this.chatInputMessage = '';

      // Update scroll position
      // Scroll to bottom
      this.$nextTick(() => {
        this.scrollToBottom();
      });
    },
    async fetchOldMessages(event) {
      if (event && !this.isMounted) {
        // Do not load messages from ps-y-reach-start when the component hasn't finished mounting.
        return;
      }
      if (this.chatError === ChatError.MessagesHistoryFailed) {
        // when the history fails we may fall into an infinite loop.
        return;
      }
      if (this.isLoadingOldMessages || this.hasNoMoreMessages) {
        return;
      }

      // store the current scroll position, so we can keep the user in that position when the old messages are added.
      const scrollBottom = this.scrollTop() + this.scrollHeight();

      try {
        this.isLoadingOldMessages = true;
        let oldMessages;
        if (this.target.room) {
          oldMessages = await this.$store.dispatch(CHAT_ACTIONS.getRoomMessages, {
            room: this.target.room,
            roomType: this.target.roomType,
            fullRoomName: this.targetName,
            beforeDate: new Date(this.oldestMessageTime),
            count: 15,
          });
        } else {
          oldMessages = await this.$store.dispatch(CHAT_ACTIONS.getDirectMessages, {
            userID: this.target.user.userKey,
            beforeDate: new Date(this.oldestMessageTime),
            count: 15,
          });
        }

        this.hasNoMoreMessages = oldMessages.length === 0;
      } catch (error) {
        this.chatError = ChatError.MessagesHistoryFailed;
        this.$toast({
          component: ToastificationContent,
          props: {
            title: this.$t('chat.error-messages.load-of-history-failed'),
            icon: 'AlertTriangleIcon',
            variant: 'danger',
          },
        });
      }

      // I need to make sure that is not connecting anymore, so that the chat-log is visible to scroll
      this.isConnecting = false;

      // If the method is called without an event the it should just scroll to the bottom, otherwise
      // the user is scrolling up, so the scroll needs to stay put.
      await this.$nextTick();
      this.scrollToBottom(event ? scrollBottom : 0);

      this.isLoadingOldMessages = false;
    },
    /**
     * Used to scroll to bottom only when a new message arrives and the scroll was already at he bottom
     */
    beforeNewMessageCallback() {
      this.shouldUpdateScroll = this.offsetHeight() + this.scrollTop() >= this.scrollHeight();
    },
    /**
     * Used to scroll to bottom only when a new message arrives and the scroll was already at he bottom
     */
    afterNewMessageCallback() {
      // Update scroll position
      // Scroll to bottom
      if (this.shouldUpdateScroll) {
        this.$nextTick(() => {
          this.scrollToBottom();
        });
      }
    },
    offsetHeight() {
      return this.$refs.refChatLogPS.getOffsetHeight();
    },
    scrollHeight() {
      return this.$refs.refChatLogPS.getScrollHeight();
    },
    scrollTop() {
      return this.$refs.refChatLogPS.getPosition().scrollTop;
    },
    /**
     * Scrolls to `scrollBottom` pixels from the bottom of the container.
     * @param {number} scrollBottom Defaults to 0.
     */
    scrollToBottom(scrollBottom = 0) {
      if (this.$refs.refChatLogPS) {
        this.$refs.refChatLogPS.scrollToBottom(scrollBottom, 0);
      }
    },
  },
};
</script>
<style lang="scss">
@import '@core/scss/base/pages/app-chat-list.scss';
</style>

<style lang="scss" scoped>
.chat-widget {
  overflow: hidden;
  position: relative;

  .placeholder-img {
    width: 120px;
    display: block;
  }
  .centered-message {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    text-align: center;
    width: 100%;
    p {
      text-align: center;
      font-weight: 500;
    }
  }
  &.stretch {
    .chat-app-window {
      height: 90vh !important;
      display: flex;
      flex-direction: column;
      flex: 1 1 auto;
      .user-chats {
        flex: 1 1 1px;
        padding-top: 1rem !important;
        padding-bottom: 1rem !important; //Neccessary important
      }
    }
  }
}
.landing-layout {
  .chat-widget.stretch .chat-app-window .user-chats {
    min-height: 50vh;
  }
}

.text-editor::v-deep {
  height: auto;
  .ql-container {
    height: auto;
  }
  .ql-editor {
    min-height: 21px;
  }
}
</style>
